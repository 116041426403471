import React from "react"
import Layout from "../components/layout"
import Infocard from "../components/infocard"
import style from "../styles/sites.module.css"
import { graphql } from "gatsby"
import SEO from "../components/seo"

function vitality({ data }) {
  return (
    <Layout>
      <SEO
        title="Vitality"
        description="Stärke die Grundfunktionen deines Körpersund steigere dein Wohlbefinden. Ein Termin beginnt immer mit einer Vitalstoffanalyse um deinen Bedarf zu erheben."
      />
      <h1>Optimierte Lebensqualität mit Nobusan</h1>
      <p>
        Stärke die Grundfunktionen deines Körpers, schließe Versorgungslücken
        und steigere deine Wohlbefinden - in jedem Alter. Ein Vitality Termin
        beginnt immer mit einer ausführlichen Vitalstoffanalyse und gemeinsam
        entwickeln wir deinen persönlichen Fahrplan um deine Ziele zu erreichen.{" "}
      </p>

      <section className={style.services}>
        {data.vitality.nodes.map(item => {
          return <Infocard data={item} key={item.id} />
        })}
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    vitality: allContentfulVitality(sort: { order: ASC, fields: order }) {
      nodes {
        text {
          childMarkdownRemark {
            html
          }
        }
        id
        title
        image {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`

export default vitality
